import { useLoaderData } from "@remix-run/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { supabase } from "~/clients/supabase";
import type { AuthState } from "~/queries/auth";
import { useAuthStateQuery } from "~/queries/auth";
import type { loader as rootLoader } from "~/root";

export const FetchAuthState = () => {
  const { user } = useLoaderData<typeof rootLoader>();
  const queryClient = useQueryClient();

  useAuthStateQuery({
    initialData:
      user === null
        ? undefined
        : {
            signedIn: true,
            user: user,
          },
  });

  useEffect(() => {
    let isSubscribed = true;

    supabase.auth.onAuthStateChange((event, session) => {
      if (!isSubscribed) return;

      if (event === "SIGNED_OUT") {
        // delete cookies on sign out
        const expires = new Date(0).toUTCString();
        document.cookie = `saa-retreats-access-token=; path=/; expires=${expires}; SameSite=Lax; secure`;
        document.cookie = `saa-retreats-refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`;

        const authState: AuthState = {
          signedIn: false,
          user: null,
        };
        queryClient.setQueryData(["auth"], authState);
      } else if (
        (event === "SIGNED_IN" ||
          event === "TOKEN_REFRESHED" ||
          event === "INITIAL_SESSION") &&
        session !== null
      ) {
        const maxAge = 100 * 365 * 24 * 60 * 60; // 100 years, never expires
        document.cookie = `saa-retreats-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
        document.cookie = `saa-retreats-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;

        const authState: AuthState = {
          signedIn: true,
          user: session.user,
        };
        queryClient.setQueryData(["auth"], authState);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, [queryClient]);

  return null;
};
