import { NavigationProgress, nprogress } from "@mantine/nprogress";
import { useNavigation } from "@remix-run/react";
import { useEffect } from "react";

export const RouteNavigationProgress = () => {
  const { state } = useNavigation();

  useEffect(() => {
    if (state === "loading") {
      nprogress.start();
    } else {
      nprogress.complete();
    }
  }, [state]);

  return <NavigationProgress />;
};
