import "@mantine/core/styles.css?__remix_sideEffect__";
import "@mantine/dates/styles.css?__remix_sideEffect__";
import "@mantine/notifications/styles.css?__remix_sideEffect__";
import "@mantine/nprogress/styles.css?__remix_sideEffect__";
import "@mantine/tiptap/styles.css?__remix_sideEffect__";
import "./style/main.css?__remix_sideEffect__";

import { ColorSchemeScript, Container, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { cssBundleHref } from "@remix-run/css-bundle";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError } from
"@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouteNavigationProgress } from "~/features/RouteNavigationProgress";
import { FetchAuthState } from "~/features/authentication/FetchAuthState";
import { getUser } from "~/lib/auth.server";
import { browserSettingsSchema } from "~/settings";
import type { Handle } from "~/types/Handle";

const queryClient = new QueryClient();

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];


export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await getUser(request);

  return json({
    env: browserSettingsSchema.parse(process.env),
    user
  });
};

export default function App() {
  const { env } = useLoaderData<typeof loader>();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>

      <body>
        <QueryClientProvider client={queryClient}>
          <FetchAuthState />
          <MantineProvider>
            <RouteNavigationProgress />
            <Notifications />

            <Container size="md">
              <Outlet context={{ env }} />
            </Container>

            <ScrollRestoration />

            <script
              dangerouslySetInnerHTML={{
                __html: `window.env = ${JSON.stringify(env)}`
              }} />

            <Scripts />

            <LiveReload />
          </MantineProvider>
        </QueryClientProvider>
      </body>
    </html>);

}

export const handle: Handle = {
  breadcrumb: "Home"
};

export const ErrorBoundary = () => {
  const error = (useRouteError() as any);

  const style = {
    margin: "2rem auto",
    maxWidth: "960px"
  };

  if (error.status !== 404) {
    captureRemixErrorBoundaryError(error);
  }

  return (
    <div style={style}>
      {error.status === 404 ?
      <>
          <h2>There's nothing here...</h2>
          <p>
            Go back to the <a href="/">homepage</a> and try again.
          </p>
          <p>
            If you keep ending up here, please email{" "}
            <a href="mailto:retreat@simplyalwaysawake.com">
              retreat@simplyalwaysawake.com
            </a>{" "}
            for support.
          </p>
        </> :

      <>
          <h2>Something went wrong</h2>
          <p>
            We got notified of the error and are looking into it. Try going back
            to the <a href="/">homepage</a> and see if the error keeps
            happening.
          </p>
          <p>
            If it does, please email{" "}
            <a href="mailto:retreat@simplyalwaysawake.com">
              retreat@simplyalwaysawake.com
            </a>{" "}
            for support.
          </p>
        </>}

    </div>);

};